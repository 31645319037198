// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap")

global.$ = require("jquery")
require("jquery")
require("jquery-ui")
require("./stylesheets/main.scss")


import Highcharts from 'highcharts';

require('highcharts/highcharts-more')(Highcharts)
require('highcharts/modules/solid-gauge')(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)
//require('packs/highcharts/modules/accessibility')(Highcharts)
require('highcharts/modules/no-data-to-display')(Highcharts)
require('highcharts/modules/draggable-points')(Highcharts)
require('highcharts/modules/annotations')(Highcharts)

// Custom javascript
require("packs/custom/custom")

window.Highcharts = Highcharts;
import Calendar from 'js-year-calendar';
import 'js-year-calendar/dist/js-year-calendar.css';

import "@fortawesome/fontawesome-free/js/all";
//import "font-awesome";


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
