// TOGGLE SIDE MENU
$(document).on('turbolinks:load', function() {

  $('#btn-mainmenu').on('mouseenter', function () {
    $('#mainmenu').addClass('open');
    $('#usermenu').removeClass('open');

    $('#mainmenu').on('mouseleave', function () {
      $('#mainmenu').removeClass('open');
    })
  })


  $('#btn-usermenu').on('mouseenter', function () {
    $('#usermenu').addClass('open');
    $('#mainmenu').removeClass('open');

    $('#usermenu').on('mouseleave', function () {
      $('#usermenu').removeClass('open');
    })
  })

  $('[data-toggle="popover"]').popover({
    trigger:"hover"
  });

  // $('[data-toggle="popover"]').popover({
  //   trigger:"manual"
  // }).popover('show');


  $('#copilot').click(function(){
    $('[data-toggle="popover"]').toggleClass('invisible');
    const url = '/welcome/copilot';
    const data = '';
    $.post(url, data, function(data, status) { })
  });

  $('#btn-forecast').click(function(){
    const url = '/welcome/runforecast';
    const data = '';
    $.post(url, data, function(data, status) { })
  });

  $('#export-forecast').click(function(){
    const url = '/welcome/runexport';
    const data = '';
    $.post(url, data, function(data, status) { })
  });

  $('#export-daily').click(function(){
    const url = '/welcome/runexportdaily';
    const data = '';
    $.post(url, data, function(data, status) { })
  });

  $('#export-week').click(function(){
    const url = '/welcome/runexportweekly';
    const data = '';
    $.post(url, data, function(data, status) { })
  });

  for (let e of document.querySelectorAll('input[type="range"]')) {
    e.style.setProperty('--value', e.value);
    e.style.setProperty('--min', e.min == '' ? '0' : e.min);
    e.style.setProperty('--max', e.max == '' ? '100' : e.max);
    e.addEventListener('input', () => e.style.setProperty('--value', e.value));
  }


  // Add animation if card enters viewport
  const container = document.querySelector(".container-fluid")
  const wrappers = document.querySelectorAll(".row")
  const cards = document.querySelectorAll(".card")

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      const currentIndex = Array.from(wrappers).indexOf(entry.target)
      if (entry.isIntersecting) {
        cards[currentIndex].classList.add("animated--fade-in-bottom")
      } else {
        if (entry.boundingClientRect.y > 0) {
          cards[currentIndex].classList.remove("animated--fade-in-bottom")
        }
      }
    })
  }, {
    root: container,
    threshold: 0.1,
  })
  
  
  wrappers.forEach(wrapper => {
    observer.observe(wrapper)
  })


})